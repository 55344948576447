.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  color: white;
  padding-left: 1rem;
}

.CanvasWrapper {
  height: 90vh;
  flex: 1;
  position: relative;
}

.Overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  opacity: 0.8;
  background-color: #282c34;
  z-index: 1;
  color: white;
  border-top: 1px solid #61dafb;
  padding: 1rem;
  /* width: 100%; */
  text-align: left;
  font-size: 14px;
}

.OverlayFooter {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  opacity: 0.8;
  /* background-color: #282c34; */
  z-index: 1;
  color: white;
  /* border-top: 1px solid #61dafb; */
  padding: 1rem;
  /* width: 100%; */
  text-align: left;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Attribution {
  opacity: 0.8;
  background-color: #282c34;
  margin-bottom: 1rem;
  text-align: right;
  padding: 0.5rem;
  font-size: 8px;
}

.Controls {
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.ControlKey {
  padding-right: 0.5rem;
}

.PrimaryCanvas {
  height: 100%; 
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
